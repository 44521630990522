@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Abel", "Grandstander";
}


  /* For WebKit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }