.App {
  text-align: center;
}

.transparent {
  width: 100%;
  background-image: url("../src/assets/Banner/transaprent\ bg.png");
  /* background-repeat: no-repeat;
  background-size:cover; */
  /* background-position: ; */
}
